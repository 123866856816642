@font-face {
  font-family: "Axiforma";
  src: url("./Axiforma-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Axiforma";
  src: url("./Axiforma-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Axiforma";
  src: url("./Axiforma-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Axiforma";
  src: url("./Axiforma-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Axiforma";
  src: url("./Axiforma-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Axiforma";
  src: url("./Axiforma-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Axiforma";
  src: url("./Axiforma-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Axiforma";
  src: url("./Axiforma-Thin.ttf") format("truetype");
  font-weight: 100;
}
