@import url("./assets/fonts/fonts.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Axiforma";
}

.primary-btn {
  background: radial-gradient(
    circle,
    rgba(237, 131, 33, 1),
    rgba(243, 186, 47, 1)
  );
}

.secondary-btn-border {
  position: relative;
}

.secondary-btn-border::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 1.5px;
  border-radius: 6px;
  background: linear-gradient(
    90deg,
    rgba(243, 186, 47, 1),
    rgba(226, 118, 37, 0.1)
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.text-outline {
  -webkit-text-fill-color: #04011c;
  -webkit-text-stroke: 3px rgba(243, 186, 47, 1);
  -webkit-text-stroke-width: 1px;
}

.dark-text-outline {
  -webkit-text-fill-color: white;
  -webkit-text-stroke: 3px #04011c;
  -webkit-text-stroke-width: 1px;
}

html {
  scroll-behavior: smooth;
}

.spinning-image {
  animation: spin 3s linear infinite;
}

.hero-image {
  animation: spin 10s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.w-full-important {
  width: 100% !important;
}

.jfPlkw {
  color: #ed4b9e;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 4px;
  font-size: 12px;
}

